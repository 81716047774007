import { Environments } from '../types/env.types';

export const checkEnv = (): void => {
  // if not set assume production
  if (!process.env.REACT_APP_ENV) {
    process.env.REACT_APP_ENV = Environments.PROD;
  }
  console.info(`Running with REACT_APP_ENV: ${process.env.REACT_APP_ENV}`);
};

export const isProd = (): boolean => process.env.NODE_ENV === Environments.PROD;

export const isMaintenanceMode = (): boolean =>
  process.env.REACT_APP_FW_MAINTENANCE_MODE?.toLowerCase() === 'true' ?? false;
