import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import fwLogoHorizontal from '../../../assets/images/fw-horizontal-web-logo-full-colour-rgb.svg';
import fwLogoDarkHorizontal from '../../../assets/images/fw-horizontal-web-logo-inverted-rgb.svg';
import fwLogo from '../../../assets/images/fw-vertical-web-logo-full-colour-rgb.svg';
import fwLogoDark from '../../../assets/images/fw-vertical-web-logo-inverted-rgb.svg';
import { ThemeType } from '../../../types/themes.types';

interface LogoProps {
  width: number;
  horizontal?: boolean;
}

export const Logo = ({ width, horizontal }: LogoProps) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.type === ThemeType.DARK;
  let logo = null;
  if (horizontal) {
    logo = isDarkMode ? fwLogoDarkHorizontal : fwLogoHorizontal;
  } else {
    logo = isDarkMode ? fwLogoDark : fwLogo;
  }
  return <img src={logo} alt="logo" width={width} />;
};

Logo.defaultProps = {
  horizontal: false,
};
