import { Box, Typography } from '@material-ui/core';
import * as React from 'react';
import { FAIRWHISTLE_SLOGAN } from '../../constants/branding.constants';
import { Logo } from '../images/logo/logo.component';
import { BoldMaintenanceText } from './maintenance-mode.styles';

export const MaintenanceMode = () => {
  const maintenanceWindow =
    process.env.REACT_APP_FW_MAINTENANCE_WINDOW ?? 'We expect to be up in a couple hours';

  return (
    <Box
      marginTop={5}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Logo width={200} />

      <Typography variant="subtitle2" color="textSecondary" align="center">
        {FAIRWHISTLE_SLOGAN}
      </Typography>

      <BoldMaintenanceText color="textSecondary" align="center" style={{ marginTop: 50 }}>
        Currently undergoing scheduled maintenance
      </BoldMaintenanceText>
      <BoldMaintenanceText color="textSecondary" align="center">
        {maintenanceWindow}
      </BoldMaintenanceText>
      <BoldMaintenanceText color="textSecondary" align="center" style={{ marginTop: 10 }}>
        Thanks for your patience
      </BoldMaintenanceText>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        align="center"
        style={{ marginTop: 20 }}
      >
        For any questions please contact <a href="mailto:support@fairwhistle.com">support</a>
      </Typography>
    </Box>
  );
};
